<template>
<div>
  <p>Introducing</p>
  <h1>Newton's Laws</h1>
</div>
</template>

<script>
export default {
  name: 'TitleBlock'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
div {
  color: white;
  margin: auto;
  text-align: center;
}

h1 {
  font-family: 'Yeseva One', sans-serif;
  font-weight: normal;
  font-size: 3em;
}

p {
  letter-spacing: 1ch;
}
</style>
